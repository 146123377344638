import React from 'react';

const IconPlaybook = props => (
  <svg width="60" height="60" viewBox="0 0 105 106" {...props}>
    <path
      d="M103.92 31.16L56.42 58.59C56.1162 58.7655 55.7715 58.8579 55.4206 58.858C55.0697 58.8581 54.7249 58.7658 54.4209 58.5905C54.117 58.4152 53.8644 58.1631 53.6888 57.8593C53.5131 57.5556 53.4204 57.2109 53.42 56.86V2.00003C53.4204 1.64913 53.5131 1.3045 53.6888 1.00074C53.8644 0.696991 54.117 0.444806 54.4209 0.269512C54.7249 0.0942178 55.0697 0.00198517 55.4206 0.00207526C55.7715 0.00216535 56.1162 0.094575 56.42 0.270025L103.92 27.7C104.223 27.8758 104.475 28.1282 104.65 28.4318C104.824 28.7354 104.917 29.0796 104.917 29.43C104.917 29.7804 104.824 30.1246 104.65 30.4283C104.475 30.7319 104.223 30.9842 103.92 31.16Z"
      fill="#25B7D4"
    />
    <path
      d="M77.21 77.52L29.71 105C29.4061 105.175 29.0614 105.268 28.7105 105.268C28.3596 105.268 28.0148 105.176 27.7108 105.001C27.4069 104.825 27.1544 104.573 26.9787 104.269C26.803 103.966 26.7103 103.621 26.71 103.27V48.37C26.7103 48.0191 26.803 47.6745 26.9787 47.3707C27.1544 47.067 27.4069 46.8148 27.7108 46.6395C28.0148 46.4642 28.3596 46.372 28.7105 46.3721C29.0614 46.3722 29.4061 46.4646 29.71 46.64L77.21 74.07C77.5107 74.2465 77.7601 74.4985 77.9334 74.8011C78.1067 75.1037 78.1978 75.4463 78.1978 75.795C78.1978 76.1437 78.1067 76.4864 77.9334 76.7889C77.7601 77.0915 77.5107 77.3436 77.21 77.52V77.52Z"
      fill="#FFAF00"
    />
    <path
      d="M50.5 31.16L3 58.59C2.69611 58.7655 2.35141 58.8579 2.00051 58.858C1.64961 58.8581 1.30487 58.7658 1.00089 58.5905C0.696909 58.4152 0.444401 58.1631 0.268718 57.8593C0.0930342 57.5556 0.000359707 57.2109 0 56.86L0 2.00003C0.000359707 1.64913 0.0930342 1.3045 0.268718 1.00074C0.444401 0.696991 0.696909 0.444806 1.00089 0.269512C1.30487 0.0942178 1.64961 0.00198517 2.00051 0.00207526C2.35141 0.00216535 2.69611 0.094575 3 0.270025L50.5 27.7C50.8031 27.8758 51.0547 28.1282 51.2295 28.4318C51.4044 28.7354 51.4965 29.0796 51.4965 29.43C51.4965 29.7804 51.4044 30.1246 51.2295 30.4283C51.0547 30.7319 50.8031 30.9842 50.5 31.16Z"
      fill="#FF5D5D"
    />
  </svg>
);

export default IconPlaybook;
